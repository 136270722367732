
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'LevelUpDialog',
  components: {},
  props: {
    onlyEmit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    dialogLevelUp: false,
    loading: false,
    isRioNegro: process.env.FB_PROJECT_ID === 'os-arg-provincia-rio',
  }),
  computed: {
    ...mapGetters({
      userLevelCidi: 'users/getLevelCidi',
      autenticar: 'authconfig/autenticar',
    }),
  },
  beforeMount() {
    localStorage.removeItem('backToReino')
  },
  methods: {
    validateLevelCidi() {
      if (this.autenticar && this.userLevelCidi < 2 && !this.isRioNegro) {
        return true
      }
      return false
    },
    async autenticarLevelUp(reino: string) {
      this.loading = true
      await this.$autenticar.init(reino)
    },
  },
})
