import { includes } from 'lodash'
export const state = () => ({
  user: {
    user: {},
  },
  autenticarUser: {
    first_name: null,
    autenticated: null,
    last_name: null,
    activated: false,
    dni: null,
    levelCidi: null,
    alreadyRegister: null,
    hasLevelRequired: null,
    levelUpdated: null,
    tipoPersona: null,
    created: null,
    uid: null,
  },
  locale: 'es',
  isHomeTop: true,
  currentUrl: '',
  loggedIn: false,
  grecaptcha: null,
})

export const mutations = {
  async setReCaptcha(state, payload) {
    state.grecaptcha = await payload
  },
  ON_AUTH_STATE_CHANGED_MUTATION: (state, { authUser }) => {
    // , _claims }) => {
    if (!authUser) {
      // claims = null
      // perform logout operations
      state.user = {
        user: {},
      }
      state.loggedIn = false
    } else {
      const { uid, email, emailVerified, phoneNumber, role, auxRole } = authUser
      const typeProfile =
        role &&
        includes(
          ['superadmin', 'admin', 'operator', 'editor', 'communicator'],
          role
        )
          ? 'Funcionario'
          : auxRole && includes(['officer'], auxRole)
          ? 'Funcionario'
          : 'Ciudadano'

      state.user = { uid, email, emailVerified, phoneNumber, role, typeProfile }
      state.loggedIn = true
    }
  },
  SET_LOCALE(state, locale) {
    state.locale = locale
  },
  SET_HOME_TOP(state, isTop) {
    state.isHomeTop = isTop
  },
  SET_HOST(state, host) {
    state.currentUrl = host
  },
  SET_AUTENTICAR(state, { autenticar }) {
    // eslint-disable-next-line no-console
    console.log('Actualizando el store de autenticarUser')
    const data = { ...state.autenticarUser, ...autenticar }
    state.autenticarUser = Object.assign({}, state.autenticarUser, data)
  },
  SET_LOGGEDIN(state, loggedIn) {
    state.loggedIn = loggedIn
  },
}

export const actions = {
  onAuthStateChangedAction: ({ commit }, { authUser }) => {
    commit('ON_AUTH_STATE_CHANGED_MUTATION', { authUser })
  },
  changeLocale({ commit }, locale) {
    commit('SET_LOCALE', locale)
  },
  setUserAutenticar({ commit }, { autenticar }) {
    commit('SET_AUTENTICAR', { autenticar })
  },
  setloggedIn({ commit }, loggedIn) {
    commit('SET_LOGGEDIN', loggedIn)
  },
  setHost({ commit }) {
    const currentUrl = `${window.location.protocol}//${window.location.host}`
    commit('SET_HOST', currentUrl)
  },
}

export const getters = {
  loggedIn(state) {
    return state.loggedIn
  },
  isHomeTop(state) {
    return state.isHomeTop
  },
  currentUrl(state) {
    return state.currentUrl
  },
  autenticarUser(state) {
    return state.autenticarUser
  },
}
