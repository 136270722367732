
import Vue from 'vue'
import { cloneDeep, startsWith } from 'lodash'
import { mapGetters, mapMutations, mapState } from 'vuex'
import firebase from 'firebase/compat/app'
import { setUserDoc } from '@/services/User'
import { updateBrand } from '@/services/Brand'
import Logo from '@/components/public/Logo.vue'
import AvatarMenu from '@/components/public/AvatarMenu.vue'
import MiniQrRegister from '@/components/Auth/MiniQrRegister.vue'
import SendEmailVerificationMixin from '@/mixins/SendEmailVerificationMixin'
import AvatarMenuCompanies from '@/components/Companies/AvatarMenuCompanies.vue'

declare module 'vue/types/vue' {
  interface Vue {
    $autenticar: any
  }
}

declare global {
  interface Window {
    recaptchaVerifierPasswordReset: any
    recaptchaWidgetPasswordResetId: any
  }
}

export default Vue.extend({
  name: 'Header',
  components: {
    Logo,
    AvatarMenu,
    MiniQrRegister,
    AvatarMenuCompanies,
  },
  mixins: [SendEmailVerificationMixin],
  props: {
    drawerSidebar: {
      type: Boolean,
      default: true,
      required: true,
    },

    typeProfile: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: false,
      walletMenu: false,
      windowSize: {
        x: 0,
        y: 0,
      },
      isEditing: false,
      loading: false,
      header_dialog: false,
      linear_color: this.$t('editor.linear_color'),
      backgroundTypes: [
        { type: 'lineal', text: this.$t('editor.linear_color') },
        { type: 'gradient', text: this.$t('editor.gradient_color') },
      ],
      snackbar: {
        show: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
        right: '',
        top: '',
      },
      clipped: false,
      dialogUnsatisfactoryLevel: false,
      backToReino: localStorage.getItem('backToReino'),
      reino: localStorage.getItem('reino'),
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user',
      brand: 'brand/brand',
      editedTexts: 'landingtext/landingtext',
      activeModules: 'modules/getActiveModules',
      userAuxRole: 'users/getAuxRole',
      autenticarUser: 'autenticarUser',
    }),
    ...mapState({
      modules: (state: any) => state.modules.modules,
    }),
    brandSettings(): any {
      return cloneDeep(this.brand)
    },
    cssVars(): any {
      return { '--header_color': this.brandSettings.header_color }
    },
    header_background(): string {
      return this.brandSettings.header_background_type === 'lineal'
        ? `background-color: ${this.brandSettings.header_bg_primary}`
        : this.brandSettings.header_background_type === 'gradient'
        ? `background-image: linear-gradient(to right, ${this.brandSettings.header_bg_primary}, ${this.brandSettings.header_bg_secondary}`
        : 'background-image: linear-gradient(to right, #0096da, #035d98)'
    },
    ventanillaDigital(): any {
      const ventanillaDigital = this.modules.find(
        (module: any) => module.id === 'ventanillaDigital'
      )
      return ventanillaDigital || { active: false }
    },
    isInRegister(): any {
      return (
        startsWith(this.$route.path, '/auth/completeData') ||
        this.$route.path === '/auth/linkWallet'
      )
    },
  },
  watch: {
    'autenticarUser.hasLevelRequired'(newValue) {
      if (this.reino !== 'renaper' && newValue !== null && this.backToReino) {
        if (!newValue) {
          // User from autenticar doesn't have the level required
          this.dialogUnsatisfactoryLevel = true
          ;['backToReino', 'reino', 'reload'].forEach((item) =>
            localStorage.removeItem(item)
          )
        } else {
          this.$router.push({ path: '/auth/register' })
        }
      } else if (this.reino === 'renaper') {
        this.$router.push({ path: '/auth/register' })
      }
    },
  },
  beforeMount() {
    if (
      this.reino !== 'renaper' &&
      this.autenticarUser.hasLevelRequired !== null &&
      this.backToReino
    ) {
      if (!this.autenticarUser.hasLevelRequired) {
        // User from autenticar doesn't have the level required
        this.dialogUnsatisfactoryLevel = true
        ;['backToReino', 'reino', 'reload'].forEach((item) =>
          localStorage.removeItem(item)
        )
      } else {
        this.$router.push({ path: '/auth/register' })
      }
    } else if (this.reino === 'renaper') {
      this.$router.push({ path: '/auth/register' })
    }
  },
  async mounted() {
    this.onResize()
    if (this.$store.getters['authconfig/getsso']) {
      await this.getRedirect()
    }
  },
  methods: {
    async getRedirect() {
      await firebase
        .auth()
        .getRedirectResult()
        .then(async (result) => {
          this.loading = true
          let userBuvData: any
          if (result.credential) {
            this.$store.commit(
              'users/SET_VILO_TOKEN',
              (result.user as any).refreshToken
            )
            let data: any = {
              email: result?.user?.email || '',
              first_name: result?.user?.displayName?.split(' ')[0],
              last_name: result?.user?.displayName?.split(' ')[1],
              public_address: '',
              activated: true,
              uid: result?.user?.uid || '',
              role: this.user.role || 'user',
            }
            if (this.$store.getters['authconfig/autenticar']) {
              data.levelCidi = this.user.levelCidi
            }
            const { uid, email } = data
            let buvSelectedData: any = {}
            try {
              userBuvData = await this.$axios.$post(
                '/vilobuv-searchNeighbor-searchNeighbor',
                {
                  email,
                }
              )
              userBuvData = userBuvData[0]
              /* cp, calle y num_ext es un campo compartido pa todas y pa ViLo
                El orden del split como pusimos la fn es Street, number, postal_code, location
              */
              const [street, number, postalCode, location] =
                userBuvData.direccion.split(',')
              buvSelectedData = {
                first_name: userBuvData.nombre || '',
                last_name: userBuvData.apellido || '',
                deptounidad: userBuvData.deptounidad || '',
                sexo: userBuvData.genero || '',
                piso: userBuvData.piso || '',
                torre: userBuvData.torre || '',
                telphone: userBuvData.telefono || '',
                direccion: userBuvData.direccion || '',
                cp: postalCode || '',
                calle: street || '',
                num_ext: number || '',
                localidad: location || '',
                dni: userBuvData.username || '',
                birthday: userBuvData.fechaDeNacimiento || '',
              }
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error(error)
            }
            data = { ...data, ...buvSelectedData }
            await setUserDoc(this.$fire, uid, {
              ...data,
              activated: true,
              totalDocumentsFinals: 0,
              tipoPersona: 'f',
              levelCidi: this.user.levelCidi,
              role: this.user.role || 'user',
              autenticated: 'renaper',
              created: firebase.firestore.Timestamp.now(),
            })
            this.$store.commit('users/SET_USER', data)
          }
        })
        .finally(() => {
          this.loading = false
        })
        .catch((error) => {
          throw error
        })
    },
    toggleEditor() {
      this.$store.dispatch('ui/toggleEditor')
    },
    copyAdressToClipBoard() {
      navigator.clipboard.writeText(this.user.public_address)
    },
    getActiveSubmodules(submodules: any) {
      return submodules?.filter((submodule: any) => submodule.active === true)
    },
    ...mapMutations({
      setBrandSettings: 'brand/SET_BRAND',
    }),
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    autenticar(reino: string) {
      this.$autenticar.init(reino)
    },
    async updateBrand() {
      this.loading = true
      try {
        await updateBrand(this.$fire, this.brandSettings.id, this.brandSettings)
        this.setBrandSettings(this.brandSettings)
      } catch {
        this.snackbar.show = true
        this.snackbar.text = 'No se pudo guardar la actualización'
        this.snackbar.color = 'error'
      }
      this.loading = false
      this.isEditing = false
      this.header_dialog = false
    },
    onFileSelected(event: any) {
      this.loading = true
      const self = this
      const storage = this.$fire.storage
      const target: string = event.target.id
      const file = event.target.files[0]
      const photosRef = storage.ref().child(`brand/images/${target}`)
      photosRef
        .put(file)
        .then((snapshot: any) => {
          snapshot.ref.getDownloadURL().then(function (downloadURL: string) {
            ;(self as any).brandSettings[target] = downloadURL
          })
        })
        .catch((err: any) => {
          this.snackbar.show = true
          this.snackbar.text = err.message
          this.snackbar.color = 'error'
        })
      this.loading = false
      this.isEditing = false
    },
    restoreDefaultLogo() {
      this.brandSettings.logo =
        'https://firebasestorage.googleapis.com/v0/b/playground-gcp-201118.appspot.com/o/brand%2F4010fc67-09a4-41a8-8851-29fc2a45d6bb?alt=media&token=984cee1d-2955-4231-807d-21dfa45b8746'
    },
    async closeDialogLevelRequired() {
      this.dialogUnsatisfactoryLevel = false
      await this.$autenticar.logout()
      localStorage.removeItem('backToReino')
      localStorage.removeItem('reino')
      localStorage.removeItem('reload')
    },
  },
})
